import React, { Component } from 'react';

// vendor components
import Helmet from 'react-helmet';

// styles
import 'stylesheets/disclaimer/index.scss';

const termlyDisclaimerId = process.env.GATSBY_TERMLY_DISCLAIMER_ID;

class Disclaimer extends Component {
  render() {
    const iframeSrc = `https://app.termly.io/document/disclaimer/${termlyDisclaimerId}`;

    return (
      <div className="disclaimer">
        <p className="disclaimer_note">
          At Trove, we are passionate about helping influencers grow and run
          thriving businesses. All of our content is based on real world
          experience, but not every situation is the same. We do not guarantee
          business outcomes and always strongly recommend consulting experts
          when making professional decisions.
        </p>
        <iframe
          className="disclaimer_iframe"
          title="Disclaimer"
          width="100%"
          src={iframeSrc}
          frameBorder="0"
          allowFullScreen
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
        <Helmet title="Disclaimer" />
      </div>
    );
  }
}

export default Disclaimer;
